import React, { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Button from 'shared-components/components/Button';
import CentralFrame from 'shared-components/components/CentralFrame';

import { Steps } from './Steps';
import style from './style.module.scss';

interface BoxContainerProps {
  title: string;
  desc?: string;

  step: number;
  maxSteps: number;
  onBack?: () => void;
  children: ReactNode;
}
//<Icon icon={BackIcon}  />

export const BoxContainer = (props: BoxContainerProps) => {
  const { title, step, maxSteps, onBack, children, desc } = props;
  return (
    <CentralFrame className={style.boxContainer}>
      {onBack && (
        <Button variant="link" onClick={onBack} className={style.backButton}>
          <FontAwesomeIcon className={style.backIcon} icon={faXmark}></FontAwesomeIcon>
        </Button>
      )}
      <h2 className={style.title}>{title}</h2>
      <p className={style.desc}>{desc || <span>&nbsp;</span>}</p>
      <div style={{ marginBottom: 32, marginTop: 20 }}>
        <Steps maxSteps={maxSteps} currentStep={step} />
      </div>
      <center>{children}</center>
    </CentralFrame>
  );
};
