import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';

import { useGateway } from 'providers/GatewayProvider';
import Icon from 'shared-components/components/Icon';
import { INIT_PAYLINK } from 'shared-components/queries/mutations';
import { Service, Method } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { BankList } from 'utils/const';
import { SEvent, State } from 'utils/stateMachine';

import style from './style.module.scss';

export const SelectBank = () => {
  const coreData = useGateway();

  const handleInitPaylinkErrors = useCallback(
    (data: any) => {
      const logs = Array.isArray(data) ? data[0] : data;
      if (logs) {
        if (logs?.message?.includes('KYC_ERROR') || logs.includes('KYC_ERROR')) {
          coreData.setters.setErrorString('There was a problem with your KYC');
          //coreData.setters.setState(GatewayState.ERROR);
        } else if (logs?.message?.includes('RFQ_ERROR') || logs.includes('RFQ_ERROR')) {
          coreData.setters.setErrorString('RFQ already used. Please try again with new payment');
          //coreData.setters.setState(GatewayState.ERROR);
        } else if (logs?.message?.includes('PAY_LINK_ERROR') || logs.includes('PAY_LINK_ERROR')) {
          coreData.setters.setErrorString('Problem communicating with bank');
          //coreData.setters.setState(GatewayState.ERROR);
        } else {
          coreData.setters.setErrorString(logs?.message);
          //coreData.setters.setState(GatewayState.ERROR);
        }
      }
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    },
    [coreData.setters, coreData.values.stateRef]
  );

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'initPayLink',
    data: {
      signed_challenge: coreData.values.authToken,
      query: INIT_PAYLINK,
      variables: {
        /*amount: coreData.values.amount.toString(),*/
        /*
        ccyName: coreData.values.currency.toLowerCase(),
        */
        bank: '',
        personId: coreData.values.pid,
        authMode: coreData.values.authMode,
        txRef: coreData.values.refId
      }
    },
    auto: false,
    onResponse: (data: any) => {
      console.log(data);
      if (data?.opId) {
        coreData.setters.setOperationId(data.opId);
        coreData.values.stateRef.send({ type: SEvent.NEXT });
      }
    },
    onErrors: handleInitPaylinkErrors
  });

  const onBankClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
      const bank = event.currentTarget.value;
      coreData.setters.setBank(bank);
      if (coreData.values.pid === undefined || coreData.values.pid.length === 0) {
        coreData.setters.setErrorString('Unexpected error: PersonID is empty');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      } else {
        query.execute({
          variables: {
            bank: bank,
            personId: coreData.values.pid,
            txRef: coreData.values.refId
          }
        });
      }
      coreData.values.stateRef.send({ type: SEvent.NEXT });
    },
    [coreData.setters, coreData.values.pid, coreData.values.refId, coreData.values.stateRef, query]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {BankList.map((bank, key) => {
        return (
          <Button
            key={key}
            value={bank.name}
            disabled={
              bank.healthy === undefined || bank.healthy === false || coreData.values.state !== State.SELECT_BANK
            }
            style={{ visibility: bank?.visible ? 'visible' : 'hidden' }}
            variant="outline-primary"
            className={style.bankButton}
            onTouchEnd={onBankClick}
            onMouseUp={onBankClick}
          >
            <Icon icon={bank.icon} />
          </Button>
        );
      })}
    </div>
  );
};
