import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';

import CircleText from 'components/CircleText';
import CentralFrame from 'shared-components/components/CentralFrame';

import style from './style.module.scss';

const Status = () => {
  const { depositRef } = useParams();
  const [getParam] = useSearchParams();
  useEffect(() => {
    window.location.href = `https://trysettle.infinipi.io/?status=${getParam.get('CurrentStatus')}`;
  });
  return (
    <>
      <center>
        <CentralFrame className={style.central}>
          <CircleText blueText="">Payment status</CircleText>
          <br />
          <br />
          <Table>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <center>{getParam.get('Response')}</center>
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Deposit reference</td>
                <td>{depositRef}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Result</td>
                <td>{getParam.get('Result')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Current status</td>
                <td>{getParam.get('CurrentStatus')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Time</td>
                <td>{getParam.get('CurrentStatusTime')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Payment ID</td>
                <td>{getParam.get('PaymentID')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Amount</td>
                <td>
                  {getParam.get('Amount')} {getParam.get('Currency')}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Company</td>
                <td>{getParam.get('Company')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Descriptor</td>
                <td>{getParam.get('Descriptor')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Depositor name</td>
                <td>{getParam.get('Depositor Name')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>IBAN</td>
                <td>{getParam.get('IBAN')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Bank</td>
                <td>{getParam.get('Bank')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Reference Number</td>
                <td>{getParam.get('Reference Number')}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: '#dfe2e6' }}>Signature</td>
                <td>{getParam.get('Signature')}</td>
              </tr>
            </tbody>
          </Table>
        </CentralFrame>
      </center>
    </>
  );
};

export default Status;
