import React, { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useGateway } from 'providers/GatewayProvider';
import { SEvent } from 'utils/stateMachine';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import { CANCEL_PAYMENT, REQUEST_KYC_APPROVAL } from 'shared-components/queries/mutations';
import { useSWCallbackKYCEvent } from 'shared-components/providers/SWEventProvider';

import style from './style.module.scss';

export const PidAck = () => {
  const coreData = useGateway();
  const [task, setTask] = useState('');

  const cancelPayment = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'cancelPay',
    data: {
      signed_challenge: coreData.values.authToken,
      query: CANCEL_PAYMENT
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.status === true) {
        coreData.setters.setErrorString('Payment process cancelled');
        coreData.values.stateRef.send({ type: SEvent.PID_ACK_NACK });
      } else {
        //coreData.values.stateRef.send({ type: SEvent.BACK });
      }
    }
  });
  const kycApproval = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'requestKycApproval',
    data: {
      signed_challenge: coreData.values.authToken,
      query: REQUEST_KYC_APPROVAL,
      variables: { personId: coreData.values.pid }
    },
    onResponse: (data: any) => {
      setTask(data.request_id);
    },
    auto: false
  });
  useSWCallbackKYCEvent((data: any) => {
    const approveData = data.approve;
    if (data?.request_id === task && approveData !== undefined) {
      if (approveData === true) {
        coreData.values.stateRef.send({ type: SEvent.PID_ACK_ACK });
      } else {
        cancelPayment.execute({ variables: { txRef: coreData.values.refId } });
      }
    }
  });

  useEffect(() => {
    kycApproval.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CountdownCircleTimer
        isPlaying
        duration={30}
        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
        colorsTime={[7, 5, 2, 0]}
        onComplete={() => {
          cancelPayment.execute({ variables: { txRef: coreData.values.refId } });
          //coreData.values.stateRef.send({ type: SEvent.FAIL });
        }}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
      <div className={style.sftext}>
        please wait while
        <br />
        <strong>{coreData.values.recipientName} </strong>
        <br />
        verifies your details
      </div>
    </>
  );
};
