import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
//import { isAndroid } from 'react-device-detect';
import QRCode from 'react-qr-code';

import { BankIDIcon, BankIDVector } from 'shared-components/assets/icons';
import Icon from '../Icon';
import WaitSpinner from '../WaitSpinner';

type BankIDProps = {
  token?: string;
  type?: string;
  redirect?: string;
  num?: number;
  buttonDescription?: string;
  progress?: number;
  onSetProgress?: (value: number) => void;
};

export const urlGenerator = () => {
  //  const isChromeOnAppleDevice = Boolean(/CriOS/.test(navigator.userAgent));
  //  const isFirefoxOnAppleDevice = Boolean(/FxiOS/.test(navigator.userAgent));
  //  const isOperaTouchOnAppleDevice = Boolean(/OPT/.test(navigator.userAgent));
  //  const isSafari = Boolean(
  //    /^(?=.*(iphone|ipad|ipod))(?=.*applewebkit)
  //(?!.*(crios|fxios|opios|chrome|android)).*/i.test(navigator.userAgent)
  //  );
  return 'null';
  /*

  if (isChromeOnAppleDevice) {
    return encodeURIComponent('googlechrome://');
  }
  if (isFirefoxOnAppleDevice) {
    return encodeURIComponent('firefox://');
  }
  if (isOperaTouchOnAppleDevice) {
    return encodeURIComponent(`${window.location.href.replace('http', 'touch-http')}`);
  }
  if (isAndroid) {
    return 'null';
  }
  if (isSafari) {
    return encodeURIComponent(`x-safari-${window.location.href}`);
  }
  return encodeURIComponent(`${window.location.href}`);
  */
};

export const BankID = ({ token, type, redirect, buttonDescription, progress, onSetProgress }: BankIDProps) => {
  if (progress === 0 && token !== undefined && token.length > 0) {
    switch (type) {
      case 'image':
      case 'qr': {
        return token.startsWith('data:') ? (
          <>
            <img src={token} alt="token" style={{ height: '256px', width: '256px' }} />
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Icon icon={BankIDIcon} />
            </div>
          </>
        ) : (
          <>
            <QRCode value={token} />
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Icon icon={BankIDIcon} />
            </div>
          </>
        );
      }
      case 'ast': {
        return (
          <>
            <Button
              className="w-100"
              style={{
                textAlign: 'center',
                color: 'white',
                fontSize: 18,
                fontWeight: '600',
                wordWrap: 'break-word'
              }}
              onClick={() => {
                onSetProgress?.call(undefined, 1);
              }}
              size="lg"
              href={
                `bankid:///?autostarttoken=${token}` + `&redirect=${redirect === undefined ? urlGenerator() : redirect}`
              }
            >
              {progress === 0 ? (
                <>
                  <Icon icon={BankIDVector} /> {buttonDescription || 'Run bankid app'}
                </>
              ) : (
                <Spinner style={{ color: 'white' }} />
              )}
            </Button>
          </>
        );
      }
      default: {
        return <div>Unsupported token type</div>;
      }
    }
  }
  return (
    <center>
      <WaitSpinner></WaitSpinner>
    </center>
  );
};
