import { and, assign, not, setup } from 'xstate';

export enum SEvent {
  FAIL = 'fail',
  CANCEL = 'cancel',
  BACK = 'back',
  PRENEXT = 'prenext',
  NEXT = 'next',
  NEED_UPGRADE = 'needUpgrade',
  ADD_RECIPIENT = 'addRecipient',
  WEBSOCKET_CONNECT = 'websocketConnect',
  WEBSOCKET_CONNECTION_FAIL = 'websocketConnectionFail',
  WEBSOCKET_CONNECTION_SUCCESS = 'websocketConnectionSuccess',
  BANKS_HEALTHY = 'banksHealthy',
  BANKS_UNHEALTHY = 'banksUnhealthy',
  PID_ACK_ACK = 'pidAckAck',
  PID_ACK_NACK = 'pidAckNack',
  PID_DISCOVERY_SUCCESS = 'pidDiscoverySuccess',
  PID_DISCOVERY_FAIL = 'pidDiscoveryFail',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  SYSTEM_READY = 'systemReady',
  SET_PID = 'setPid',
  UPDATE_DATA = 'updateData'
}

export enum State {
  UNKNOWN = 'unknown',
  IDLE = 'idle',
  PRE_BANK_HEALTH_CHECK = 'preBankHealthCheck',
  BANK_HEALTH_CHECK = 'bankHealthCheck',
  PID_DISCOVERY = 'pidDiscovery',
  SELECT_BANK = 'selectBank',
  REQUESTED = 'requested',
  BANK_AUTHENTICATE = 'bankAuthenticate',
  ACCOUNT_CHOOSE = 'accountChoose',
  ADD_RECIPIENT = 'addRecipient',
  SIGN = 'sign',
  SUCCESS = 'success',
  ERROR = 'error',
  NEED_UPGRADE = 'needUpgrade',
  PID_ACK = 'pidAck',
  AWAITING_FINALITY = 'awaitingFinality',

  // additional states from gateway
  RESOLVE_MERCHANT_BY_ID = 'RESOLVE_MERCHANT_BY_ID',
  MONITOR_AUTH = 'MONITOR_AUTH',
  AUTH_USER_INTO = 'AUTH_USER_INTO',
  LIST_ACCOUNTS = 'LIST_ACCOUNTS',
  SELECT_USER_ACCOUNT = 'SELECT_USER_ACCOUNT',
  SIGN_TX = 'SIGN_TX',
  MONITOR_SIGN_TX = 'MONITOR_SIGN_TX'
}

export const machine = setup({
  types: {
    context: {} as {
      pid: string;
      amount: string;
      currency: string;
      authorized: boolean;
      signed: boolean;
      wsConnected: boolean;
      systemReady: boolean;
      connectToWebsocket: boolean;
      banksHealthy?: boolean;
      manualVerify: boolean;
    }
  },
  actions: {
    checkBankingHealth: (/*{ context, event }*/) => {
      console.log('checkHealth');
    },
    initiatePayment: (/*{ context, event }*/) => {
      console.log('initPi');
    },
    selectAccount: (/*{ context, event }*/) => {
      console.log('selectAccount');
    }
  },
  guards: {
    alreadyConnecting: ({ context }) => {
      return !context.connectToWebsocket;
    },
    systemReady: ({ context }) => {
      return context.systemReady;
    },
    banksUnknown: ({ context }) => {
      return context.banksHealthy === undefined;
    },
    banksHealthy: ({ context }) => {
      return context.banksHealthy === true;
    },
    hasPid: ({ context }) => {
      return context.pid.length > 0;
    },
    isManualVerify: ({ context }) => {
      return context.manualVerify === true;
    },
    wsConnected: ({ context }) => {
      return context.wsConnected;
    },
    authorization: function (/*{ context, event }*/) {
      // Add your guard condition here
      return true;
    },
    signature: function (/*{ context, event }*/) {
      // Add your guard condition here
      return true;
    }
  },
  schemas: {
    schemas: {
      events: {
        websocketConnectionSuccess: {
          type: 'object',
          properties: {}
        },
        websocketConnectionFail: {
          type: 'object',
          properties: {}
        },
        pidDiscoverySuccess: {
          type: 'object',
          properties: {}
        },
        pidDiscoveryFail: {
          type: 'object',
          properties: {}
        },
        completed: {
          type: 'object',
          properties: {}
        },
        [SEvent.CANCELLED]: {
          type: 'object',
          properties: {}
        },
        [SEvent.SYSTEM_READY]: {
          type: 'object',
          properties: {}
        }
      }
    }
  }
}).createMachine({
  context: {
    pid: '',
    amount: '',
    currency: '',
    authorized: false,
    signed: false,
    wsConnected: false,
    systemReady: false,
    connectToWebsocket: false,
    manualVerify: false
  },
  id: 'PI',
  initial: State.IDLE,
  states: {
    hist: {
      type: 'history',
      history: 'deep'
    },
    [State.IDLE]: {
      entry: assign({ connectToWebsocket: () => false }),
      always: [
        {
          target: State.PRE_BANK_HEALTH_CHECK,
          guard: 'systemReady'
        }
      ],
      on: {
        [SEvent.NEXT]: {
          target: State.PRE_BANK_HEALTH_CHECK
        },
        [SEvent.FAIL]: {
          target: State.ERROR
        }
      }
    },
    [State.PRE_BANK_HEALTH_CHECK]: {
      always: [
        {
          actions: assign({ connectToWebsocket: () => true }),
          guard: 'alreadyConnecting'
        },
        {
          target: State.BANK_HEALTH_CHECK,
          guard: 'wsConnected'
        },
        {
          target: State.IDLE,
          guard: not('systemReady')
        }
      ],
      on: {
        [SEvent.NEXT]: {
          target: State.BANK_HEALTH_CHECK
        }
      }
    },
    [State.BANK_HEALTH_CHECK]: {
      on: {
        [SEvent.BANKS_HEALTHY]: [{ target: State.SELECT_BANK, guard: 'hasPid' }, { target: State.PID_DISCOVERY }],
        [SEvent.BANKS_UNHEALTHY]: { target: State.ERROR },
        [SEvent.FAIL]: {
          target: State.ERROR
        }
      }
    },
    [State.PID_DISCOVERY]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.PID_DISCOVERY_SUCCESS]: [
          { target: State.PID_ACK, guard: and(['hasPid', 'isManualVerify']) },
          { target: State.SELECT_BANK, guard: and(['hasPid', not('isManualVerify')]) }
        ],
        [SEvent.PID_DISCOVERY_FAIL]: { target: State.ERROR },
        [SEvent.FAIL]: {
          target: State.ERROR
        }
      }
    },
    [State.PID_ACK]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.PID_ACK_ACK]: { target: State.SELECT_BANK, guard: 'hasPid' },
        [SEvent.PID_ACK_NACK]: { target: State.ERROR },
        [SEvent.FAIL]: {
          target: State.ERROR
        }
      }
    },

    [State.SELECT_BANK]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.NEXT]: { target: State.REQUESTED },
        [SEvent.FAIL]: {
          target: State.ERROR
        }
      }
    },
    [State.REQUESTED]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.NEXT]: { target: State.BANK_AUTHENTICATE },
        [SEvent.FAIL]: {
          target: State.ERROR
        },
        [SEvent.BACK]: {
          target: State.SELECT_BANK
        }
      }
    },
    [State.BANK_AUTHENTICATE]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },

        [SEvent.COMPLETED]: {
          target: State.ACCOUNT_CHOOSE
        },
        [SEvent.CANCELLED]: {
          target: State.ERROR
        },
        [SEvent.FAIL]: {
          target: State.ERROR
        },
        [SEvent.BACK]: {
          target: State.SELECT_BANK
        }
      }
    },
    [State.ACCOUNT_CHOOSE]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.NEXT]: { target: State.SIGN },
        [SEvent.FAIL]: {
          target: State.ERROR
        },
        [SEvent.BACK]: {
          target: State.SELECT_BANK
        },
        [SEvent.ADD_RECIPIENT]: {
          target: State.ADD_RECIPIENT
        }
      }
    },
    [State.ADD_RECIPIENT]: {
      on: {
        [SEvent.CANCEL]: { target: State.ERROR },
        [SEvent.NEXT]: { target: State.SIGN },
        [SEvent.FAIL]: {
          target: State.ERROR
        },
        [SEvent.BACK]: {
          target: State.ACCOUNT_CHOOSE
        },
        [SEvent.NEED_UPGRADE]: {
          target: State.NEED_UPGRADE
        }
      }
    },
    [State.SIGN]: {
      on: {
        [SEvent.COMPLETED]: {
          target: State.SUCCESS
        },
        [SEvent.NEED_UPGRADE]: {
          target: State.NEED_UPGRADE
        },
        [SEvent.ADD_RECIPIENT]: {
          target: State.ADD_RECIPIENT
        },
        [SEvent.CANCELLED]: {
          target: State.ERROR
        },
        [SEvent.FAIL]: {
          target: State.ERROR
        },
        [SEvent.BACK]: {
          target: State.ACCOUNT_CHOOSE
        }
      }
    },
    [State.SUCCESS]: {
      type: 'final'
    },
    [State.ERROR]: {
      type: 'final'
    },
    [State.NEED_UPGRADE]: {
      type: 'final'
    }
  },
  on: {
    [SEvent.UPDATE_DATA]: {
      actions: assign(({ context, event }) => {
        return { ...context, ...event?.data };
      })
    }
  }
});
