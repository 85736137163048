import React, { useCallback } from 'react';

//import { AddRecipient } from 'pages/gateway-data/AddRecipient';
import CentralFrame from 'shared-components/components/CentralFrame';
//import { useGateway } from 'providers/GatewayProvider';
//import { SignTransaction } from 'pages/gateway-data/SignTransaction';
//import { NeedUpgrade } from 'pages/gateway-data/NeedUpgrade';
//import { Success } from 'pages/gateway-data/Success';
import { NeedUpgrade } from 'pages/gateway-data/NeedUpgrade';
import { BoxContainer } from 'components/PaymentFlow/components/BoxContainer';

const TestPage = () => {
  const challengeTest = useCallback(async (_: string) => {
    fetch('https://psd2.api.swedbank.com:443/psd2/available-bankids').then((response) => {
      console.log(response);
    });
    //    const key = await getPrivKeyCookie();
    //    console.log(key);
    //    console.log(await getPubKeyCookie());
    //    console.log(challenge);
    //    console.log(await getThumbprint());
    //    const token = await signData(key, { test: 'dupa' });
    //    console.log('mytoken');
    //    console.log(token);
    //console.log(pubKey);
    //console.log(signedChallenge);
  }, []);

  return (
    <>
      <center>
        <CentralFrame>
          <BoxContainer
            maxSteps={5}
            step={1}
            onBack={() => {
              challengeTest('test');
            }}
            title={'upgrade test site'}
            desc={'description to upgrade site test'}
          >
            <NeedUpgrade link={'https://www.example.org'} />
          </BoxContainer>
        </CentralFrame>
      </center>
    </>
  );
};

export default TestPage;
