import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';

import CentralFrame from 'shared-components/components/CentralFrame';
import { githash, Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useGateway } from 'providers/GatewayProvider';
import { BoxContainer } from 'components/PaymentFlow/components/BoxContainer';
import {
  useSWCallbackAccountEvent,
  useSWCallbackErrorEvent,
  useSWCallbackPaymentEvent,
  useSWCallbackSelectedEvent,
  useSWCallbackSignEvent,
  useSWCallbackStateEvent,
  useSWCallbackUpdateEvent
} from 'shared-components/providers/SWEventProvider';
import {
  escapeJSON,
  generateCryptoKeys,
  getJWK,
  getPrivKeyCookie,
  getThumbprint,
  isValidUrl,
  parseJwt,
  signData
} from 'shared-components/utils';
import { StateDataType } from 'shared-components/types/Notifications';
import { usePrevious } from 'utils';
import { useHash } from 'shared-components/hooks/useHash';
import { CANCEL_PAYMENT } from 'shared-components/queries/mutations';
import { GATEWAY_BANKINGHEALTH, GATEWAY_MERCHANTINFO, GW_RFQ } from 'shared-components/queries/graphql';
import { BankList } from 'utils/const';
import { SEvent, State } from 'utils/stateMachine';
import { StateMachineContext } from 'providers/StateMachineProvider';

import { GatewayWidget } from './GatewayWidget';
import { Success } from './Success';
import { Fail } from './Fail';

let pingTimer: any;

const title = {
  [State.UNKNOWN]: '',
  [State.IDLE]: '',
  [State.PID_DISCOVERY]: 'Personal ID Discovery',

  [State.PRE_BANK_HEALTH_CHECK]: 'Checking connections',
  [State.BANK_HEALTH_CHECK]: 'Checking connections',
  [State.PID_ACK]: 'waiting KYC verification',

  [State.SELECT_BANK]: 'Select your bank:',
  [State.REQUESTED]: 'Select your bank:',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Select your bank:',

  [State.BANK_AUTHENTICATE]: 'Authenticate',
  [State.MONITOR_AUTH]: 'Authenticate',
  [State.AUTH_USER_INTO]: 'Authenticate',

  [State.ACCOUNT_CHOOSE]: 'Select payment account:',
  [State.LIST_ACCOUNTS]: 'Select payment account:',
  [State.SELECT_USER_ACCOUNT]: 'Select payment account:',

  [State.ADD_RECIPIENT]: 'Add Recipient',

  [State.SIGN]: 'Sign the payment',
  [State.SIGN_TX]: 'Sign the payment',
  [State.MONITOR_SIGN_TX]: 'Sign the payment',

  [State.NEED_UPGRADE]: 'Upgrade account',
  [State.SUCCESS]: '',
  [State.ERROR]: '',
  [State.AWAITING_FINALITY]: ''
};

const description = {
  [State.UNKNOWN]: '',
  [State.IDLE]: 'Checking connections',
  [State.PRE_BANK_HEALTH_CHECK]: '',
  [State.BANK_HEALTH_CHECK]: '',
  [State.PID_DISCOVERY]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.PID_ACK]: '',

  [State.SELECT_BANK]: '',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Querying merchant',
  [State.REQUESTED]: 'Connecting to selected bank',

  [State.BANK_AUTHENTICATE]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_AUTH]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.AUTH_USER_INTO]: 'Authenticating user into bank',

  [State.ACCOUNT_CHOOSE]: '',
  [State.LIST_ACCOUNTS]: 'Downloading account data',
  [State.SELECT_USER_ACCOUNT]: 'Select account to pay',

  [State.ADD_RECIPIENT]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.SIGN]: isMobile ? 'Open BankID on your device by pressing the button' : 'Start the BankID app and scan the QR',

  [State.SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.NEED_UPGRADE]:
    'To add a new recipient or continue with Payment Initation, activate your Mobile BankID for extended use.',

  [State.SUCCESS]: '',
  [State.ERROR]: '',
  [State.AWAITING_FINALITY]: ''
};

const GatewayData = () => {
  const cookieExpire = useMemo(() => {
    const date = new Date(); // Current date
    const newDate = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    return newDate;
  }, []);

  const [_cookie, setCookie] = useCookies(['pid', 'key']);
  const { authToken /*, refId, depositAmount,*/ /*bankData*/ } = useParams();
  const [step, setStep] = useState(0);
  const [cancelLocked, setCancelLocked] = useState<boolean>(false);
  const coreData = useGateway();
  const connected = StateMachineContext.useSelector((state) => state.context.wsConnected);
  const gqlPrevStatus = usePrevious(connected);
  const [searchParams] = useSearchParams();

  const { hash, updateHash } = useHash();

  const merchantInfoQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'merchantInfo',
    data: {
      signed_challenge: coreData.values.authToken,
      query: GATEWAY_MERCHANTINFO,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      coreData.setters.setRecipientName(data.name);
      coreData.setters.setRecipientIban(data.iban);
    }
  });

  const rfqQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'rfq',
    data: {
      signed_challenge: coreData.values.authToken,
      query: GW_RFQ,
      variables: {
        txRef: coreData.values.refId
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const rfq = data?.edges?.map((value: any) => {
        return value.node;
      })?.[0];
      if (rfq === undefined) {
        coreData.setters.setErrorString('Payment process failed - tx not found');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      coreData.setters.setPid(rfq.clientPid === '0' ? '' : rfq.clientPid);
      if (rfq.sekDeposit === rfq.eurRequest) {
        coreData.setters.setCurrency('SEK');
        coreData.setters.setAmount(rfq.sekDeposit);
      } else {
        coreData.setters.setCurrency('EUR');
        coreData.setters.setAmount(rfq.eurRequest);
      }
      coreData.values.stateRef.send({ type: SEvent.BANKS_HEALTHY });
    }
  });

  useEffect(() => {
    updateHash('initiated');
  }, [hash, updateHash]);

  useEffect(() => {
    coreData.setters.setAuthMode(isMobile ? 'ast' : 'qr');
  }, [coreData.setters]);

  /*
  useEffect(() => {
    if (bankData === undefined) {
      //      if (searchParams.get('kycVerify') === 'true') {
      //        coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: { manualVerify: true } });
      //      }
      if (searchParams.get('token') === 'true' && cookie.pid) {
        coreData.setters.setPid(cookie.pid.toString());
        setCookie('pid', cookie.pid.toString(), {
          path: '/',
          expires: cookieExpire,
          httpOnly: false,
          secure: false
        });
      }
    } else {
      coreData.setters.setPid(bankData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankData, searchParams, cookie, cookieExpire]);
*/
  useEffect(() => {
    if (authToken === undefined) {
      return;
    }
    const tokenData = parseJwt(authToken);
    coreData.setters.setRefId(tokenData.tx_ref);
    if (coreData.values.pid === undefined || coreData.values.pid === '' || coreData.values.pid === '0') {
      coreData.setters.setPid(tokenData.client_id);
    }

    if (searchParams.get('token') === 'true' && getPrivKeyCookie(coreData.values.pid) !== null) {
      const key = getPrivKeyCookie(coreData.values.pid);
      if (key === null) {
        return;
      }
      signData(coreData.values.pid, key, { challenge: authToken }).then((value) => {
        coreData.setters.setAuthToken(value);
      });
    } else {
      coreData.setters.setAuthToken(authToken);
    }
  }, [authToken, coreData.values.pid, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const bankHealth = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'bankingHealth',
    data: {
      signed_challenge: coreData.values.authToken,
      query: GATEWAY_BANKINGHEALTH,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      try {
        const healthData = JSON.parse(data);
        const banks = healthData.getBankingHealth;
        for (const element of banks) {
          const id = BankList.findIndex((bank) => element.bank === bank.name);
          BankList[id].healthy = element.healthy;
          /* temporary disable on desktop */
          /*
          const swedid = BankList.findIndex((bank) => 'swedbank' === bank.name);
          if (isDesktop) {
            BankList[swedid].healthy = false;
          }
          
        */
          /* temporary disable on desktop */
        }
        const hasTrue = BankList.find((bank) => bank.healthy === true);
        if (hasTrue === undefined) {
          coreData.setters.setErrorString('Service unavailable. Please try again later');
          coreData.values.stateRef.send({ type: SEvent.BANKS_UNHEALTHY });
        } else {
          rfqQuery.execute({
            variables: {
              txRef: coreData.values.refId
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onErrors: (data: any) => {
      console.log(data);
    }
  });

  const ping = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'nonexistent',
    data: {
      signed_challenge: coreData.values.authToken,
      query: '{ ping }'
    },
    auto: false
  });

  const cancelPayment = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'cancelPay',
    data: {
      signed_challenge: coreData.values.authToken,
      query: CANCEL_PAYMENT
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.status === true) {
        coreData.setters.setErrorString('Payment process cancelled');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      } else {
        //coreData.values.stateRef.send({ type: SEvent.BACK });
      }
    }
  });

  const sendKeyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: '',
    data: {
      signed_challenge: coreData.values.authToken
    },
    auto: false
  });

  const sendKey = useCallback(async () => {
    let key = await getJWK(coreData.values.pid);
    if (key === null) {
      generateCryptoKeys(coreData.values.pid);
    }
    key = await getJWK(coreData.values.pid);
    const thumb = getThumbprint(coreData.values.pid);
    const keyEscaped = escapeJSON(JSON.stringify(key));
    console.log(thumb);
    console.log(keyEscaped);
    sendKeyQuery.execute({
      query: `mutation { submitKey(keyId: "${thumb}", key: "${keyEscaped}") { status } }`
    });
  }, [coreData.values.pid, sendKeyQuery]);

  useEffect(() => {
    if (searchParams.get('token') !== 'true') {
      return;
    }
    switch (coreData.values.state) {
      case State.SUCCESS: {
        if (searchParams.get('token') === 'true') {
          sendKey();
          setCookie('pid', coreData.values.pid.toString(), {
            path: '/',
            expires: cookieExpire,
            httpOnly: false,
            secure: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieExpire, coreData.values.pid, coreData.values.state, searchParams]);

  useEffect(() => {
    if (coreData.values.authToken.length > 0 && coreData.values.state === State.BANK_HEALTH_CHECK) {
      bankHealth.execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values.state, coreData.values.authToken.length]);

  useEffect(() => {
    switch (coreData.values.state) {
      case State.IDLE: {
        // Reset all locks
        break;
      }
      case State.BANK_HEALTH_CHECK: {
        merchantInfoQuery.execute();

        break;
      }
      case State.PID_DISCOVERY: {
        setStep(1);
        // Do the PID DISCOVERY WIDGET
        break;
      }
      case State.PID_ACK: {
        setStep(1);
        // Do the PID DISCOVERY WIDGET
        break;
      }

      case State.SELECT_BANK: {
        setStep(2);
        break;
      }

      case State.RESOLVE_MERCHANT_BY_ID:
      case State.REQUESTED: {
        break;
      }

      case State.MONITOR_AUTH:
      case State.AUTH_USER_INTO:
      case State.BANK_AUTHENTICATE: {
        setStep(3);
        // bank authenticate screen
        break;
      }

      case State.LIST_ACCOUNTS:
      case State.SELECT_USER_ACCOUNT:
      case State.ACCOUNT_CHOOSE: {
        coreData.setters.setToken('');
        setStep(4);
        break;
      }
      case State.SIGN_TX:
      case State.MONITOR_SIGN_TX:
      case State.SIGN: {
        setStep(5);
        // sign the payment
        break;
      }
      case State.SUCCESS: {
        //        setStep(6);
        // payment success
        break;
      }
      /*
      case State.ERROR:
        const url = new URL(settleReturnFailureUrl);
        if (coreData.values.errorString.length > 0) {
          url.searchParams.set('errorString', coreData.values.errorString);
        }
        window.location.href = url.toString();
        break;
        */

      default: {
        break;
      }
    }
    // STATE MACHINE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values.state]);

  useEffect(() => {
    if (connected === true && coreData.values.refId !== undefined) {
      if (pingTimer === undefined) {
        pingTimer = setInterval(() => {
          ping.execute();
        }, 15_000);
      }
    } else {
      clearTimeout(pingTimer);
      pingTimer = undefined;
      if (connected === false && gqlPrevStatus === true) {
        coreData.setters.setErrorString('Connection with gateway closed.');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, coreData.values.refId, ping, coreData.values.state, gqlPrevStatus, coreData.values.stateRef]);

  useSWCallbackAccountEvent((data: any) => {
    if (data?.task === coreData.values.operationId) {
      const accounts = data.accounts.sort((a: any, b: any) => {
        return Number(a.balance) < Number(b.balance) ? 1 : -1;
      });
      coreData.setters.setAccountList(accounts);
    }
  });

  useSWCallbackSelectedEvent((data: any) => {
    if (coreData.values.state === State.BANK_AUTHENTICATE) {
      coreData.values.stateRef.send({ type: SEvent.COMPLETED });
    }

    if (data?.task === coreData.values.operationId) {
      const accounts = data.accounts.sort((a: any, b: any) => {
        return Number(a.balance) < Number(b.balance) ? 1 : -1;
      });
      const selected = data.selected;
      coreData.setters.setAccountList(accounts);
      if (selected) {
        coreData.setters.setAccount(selected);
      }
    }
  });

  useSWCallbackErrorEvent((data: any) => {
    if (data.cancelOperation === null) {
      console.log(data);
    } else {
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    }
  });

  useSWCallbackSignEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const signEventData = data.piSignState;
      if (signEventData === undefined) {
        return;
      }
      if (signEventData.txStatus == 'completed') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }
      if (signEventData.paymentAuthStatus == 'failed') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }

      if (signEventData.paymentAuthStatus == 'accepted') {
        //coreData.setters.setState(State.SIGN);
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }
      if (signEventData.paymentAuthStatus == 'requested') {
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }

      if (signEventData.paymentAuthStatus == 'authReqd' && signEventData.token.length > 0) {
        if (signEventData.token.length > 300) {
          coreData.setters.setToken(`data:image/png;base64,${signEventData.token}`);
        } else {
          coreData.setters.setToken(signEventData.token);
        }
      }

      if (signEventData.paymentAuthStatus == 'recipMissing') {
        coreData.values.stateRef.send({ type: SEvent.ADD_RECIPIENT });
      }
      if (signEventData.paymentAuthStatus === 'requestAgain') {
        coreData.setters.setToken('');
        coreData.values.stateRef.send({ type: SEvent.NEXT });
        //coreData.setters.setState(State.SIGN_TX);
      }

      if (signEventData.paymentAuthStatus == 'awaitingFinality') {
        setCancelLocked(true);
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }

      if (
        signEventData.paymentAuthStatus == 'authDone' &&
        (coreData.values.bank === 'seb' || coreData.values.bank === 'handelsbank')
      ) {
        setCancelLocked(true);
        //coreData.setters.setErrorString('Payment processing in progress, you may return to the merchant site');
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
        //coreData.setters.setState(State.SUCCESS);
      }

      if (signEventData.paymentAuthStatus == 'authDone' && coreData.values.authMode === 'qr') {
        coreData.setters.setToken('');
      }
      if (signEventData.paymentAuthStatus == 'needUpgrade') {
        const url = signEventData.url;
        if (isValidUrl(url)) {
          coreData.setters.setRedirectUrl(url);
          coreData.values.stateRef.send({ type: SEvent.NEED_UPGRADE });
          //          coreData.setters.setState(State.NEED_UPGRADE);
        }
      }
      if (signEventData.paymentAuthStatus == 'requestAgain') {
        // DO SOME MESSAGE LIKE "PI is about to resume"
      }
    }
  });

  useSWCallbackUpdateEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const authState = data.piAuthState;
      if (authState.url != undefined && authState.url.length > 0) {
        window.open(authState.url, '_blank')?.focus();
      }
      if (authState.state === 'timedOut') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
        //        coreData.setters.setState(State.ERROR);
      }
      if (authState.state === 'pending') {
        //        coreData.setters.setState(State.MONITOR_AUTH);
        if (authState.token.length > 0) {
          coreData.setters.setToken(authState.token);
        } else if (authState.token.length === 0 && authState.base64Data.length > 0) {
          coreData.setters.setToken(`data:image/png;base64,${authState.base64Data}`);
          coreData.setters.setAuthMode('image');
        } else {
          if (coreData.values.authMode === 'qr') {
            coreData.setters.setToken('');
          }
        }
      }
      if (authState.state === 'rejected') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
        //        coreData.setters.setState(State.ERROR);
      }

      if (authState.state === 'success') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
        //        coreData.setters.setState(State.ACCOUNT_CHOOSE);
      }
    }
  });

  useSWCallbackPaymentEvent((data: any) => {
    const currency = coreData.values.currency;
    if (currency == 'EUR') {
      const amountInSek = Number.parseFloat(data?.sek_deposit.toString());
      coreData.setters.setAmount(amountInSek);
      coreData.setters.setCurrency('SEK');
    }
  });

  useSWCallbackStateEvent((data: StateDataType) => {
    const operationProgress = data.operationProgress;
    const state = operationProgress.state;
    const currentTask = operationProgress.currentTask;
    const paymentState = operationProgress.paymentState;

    if (
      coreData.values.state === State.NEED_UPGRADE ||
      coreData.values.state === State.SUCCESS ||
      coreData.values.state === State.ERROR ||
      paymentState === 'needUpgrade'
    ) {
      return;
    }
    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      if (state === 'failed' || state === 'cancelled') {
        //  coreData.setters.setErrorString(operationProgress.errorStr);
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      if (/sign (\w+) tx/.test(currentTask) && paymentState !== 'redo') {
        coreData.values.stateRef.send({ type: SEvent.NEXT });
      }
      if (state === 'awaitingFinality') {
        coreData.setters.setErrorString(operationProgress.errorStr);
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }
      //      const serverState = stateResolver(operationProgress);
      //      if (serverState.state !== GatewayState.UNKNOWN) {
      //        coreData.setters.setErrorString(serverState.errorMsg);
      //coreData.setters.setState(serverState.state);
      //      }
    }
  });

  switch (coreData.values.state) {
    case State.SUCCESS: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Success />
            </center>
          </CentralFrame>
        </center>
      );
    }
    case State.ERROR: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Fail />
            </center>
          </CentralFrame>
        </center>
      );
    }
    default: {
      return (
        <>
          <center>
            <CentralFrame>
              <BoxContainer
                maxSteps={5}
                step={step}
                onBack={
                  cancelLocked
                    ? undefined
                    : async () => {
                        if (coreData.values.operationId.length > 0) {
                          cancelPayment.execute({ variables: { taskId: coreData.values.operationId } });
                        } else {
                          cancelPayment.execute({ variables: { txRef: coreData.values.refId } });
                        }
                        /*
                        if (
                          coreData.values.state === State.SELECT_BANK ||
                          coreData.values.state === State.PID_DISCOVERY
                        ) {
                          coreData.setters.setErrorString('Cancelled');
                          coreData.values.stateRef.send({ type: SEvent.FAIL });
                        } else {
                          cancelPayment.execute({ txRef: coreData.values.refId });
                        }
                          */
                      }
                }
                title={title?.[coreData.values.state]}
                desc={description?.[coreData.values.state]}
              >
                <GatewayWidget
                  cancelLock={(value) => {
                    if (value == 1) {
                      setCancelLocked(true);
                    } else {
                      setCancelLocked(false);
                    }
                  }}
                />
              </BoxContainer>
            </CentralFrame>
            <div>rev: {githash}</div>
          </center>
        </>
      );
    }
  }
};

export default GatewayData;
