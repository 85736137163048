import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGateway } from 'providers/GatewayProvider';
import { isValidUrl } from 'shared-components/utils';

import style from './style.module.scss';

export const Fail = () => {
  const coreData = useGateway();
  const [searchParams] = useSearchParams();
  const [counter, setCounter] = useState(5);
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    const failRedirect = searchParams.get('failRedirect');
    const redirect = searchParams.get('redirect');

    const url = isValidUrl(failRedirect || '') ? failRedirect : isValidUrl(redirect || '') ? redirect : undefined;
    if (url) {
      setRedirectUrl(url);
      setInterval(() => {
        setCounter((prev: number) => prev - 1);
      }, 1000);
    }
  }, [searchParams]);

  useEffect(() => {
    if (counter === 0) {
      window.location.href = redirectUrl;
    }
  }, [counter, redirectUrl]);

  return (
    <>
      <div className={style.sfcircle} style={{ marginTop: '40px' }}>
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div className={style.sfheader}>Payment aborted</div>
      <div className={style.sftext}>{coreData.values.errorString}</div>
      {isValidUrl(redirectUrl) && <div className={style.sftext}>Redirecting in {counter}...</div>}
    </>
  );
};
