import React, { createContext, useContext } from 'react';

import { BaseProps } from '../utils/types';
import { Method, Service } from 'shared-components/configuration';
import useSWComm from 'shared-components/hooks/useSWComm';

const ServiceWorkerStatusContext = createContext<any>({});

export const useServiceWorkerStatus = () => {
  const context = useContext(ServiceWorkerStatusContext);
  if (!context) {
    throw new Error('UpdateEventContext not available');
  }
  return context;
};

const ServiceWorkerStatusProvider = ({ children }: BaseProps) => {
  const graphqlConnected = useSWComm({
    service: Service.GRAPHQL,
    method: Method.CONNECT
  });
  const graphqlAuthorized = useSWComm({
    service: Service.GRAPHQL,
    method: Method.AUTHORIZED
  });
  const authEidConnected = useSWComm({
    service: Service.AUTHEID,
    method: Method.CONNECT
  });

  return (
    <ServiceWorkerStatusContext.Provider value={{ graphqlConnected, graphqlAuthorized, authEidConnected }}>
      {children}
    </ServiceWorkerStatusContext.Provider>
  );
};

export default ServiceWorkerStatusProvider;
