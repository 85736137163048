import React, { useMemo } from 'react';

import Icon from 'shared-components/components/Icon';
import { StepDoneIcon } from 'shared-components/assets/icons';

import styles from './style.module.scss';

interface StepsProps {
  maxSteps: number;
  currentStep: number;
}

export const Steps = ({ maxSteps, currentStep }: StepsProps) => {
  const nextStepItem = useMemo(() => {
    return <div className={styles.nextStep} />;
  }, []);

  const currentStepItem = useMemo(() => {
    return <div className={styles.currentStep} />;
  }, []);

  const previousStepItem = useMemo(() => {
    return <Icon icon={StepDoneIcon} className={styles.p0} />;
  }, []);

  const lineItem = useMemo(() => {
    return <span className={styles.line} />;
  }, []);

  const getItem = (index: number) => {
    if (index < currentStep) {
      return previousStepItem;
    }

    if (index === currentStep) {
      return currentStepItem;
    }
    if (index > currentStep) {
      return nextStepItem;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {Array.from({ length: maxSteps || 4 }).map((_item, index) => {
        const indexStep = index + 1;
        const icon = getItem(indexStep);
        const line = indexStep < maxSteps && lineItem;

        return (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            {icon}
            {line}
          </div>
        );
      })}
    </div>
  );
};
