import React from 'react';
import { Spinner } from 'react-bootstrap';

import { BaseProps } from 'shared-components/utils/types';

const WaitSpinner = ({ children }: BaseProps) => {
  return (
    <>
      <center>
        <Spinner
          data-testid="wait-spinner"
          style={{
            height: '62px',
            width: '62px',
            color: '#2293FB',
            fontSize: '24px'
          }}
        />
        <br />
        {children}
      </center>
    </>
  );
};

export default WaitSpinner;
