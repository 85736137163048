import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import useSWComm from 'shared-components/hooks/useSWComm';
import { BaseProps } from 'shared-components/utils/types';
import { SEvent, State } from 'utils/stateMachine';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

import { StateMachineContext } from './StateMachineProvider';

export const enum GatewayState {
  UNKNOWN,
  IDLE,
  PID_DISCOVERY,
  SELECT_BANK,
  REQUESTED,
  BANK_AUTHENTICATE,
  ACCOUNT_CHOOSE,
  ADD_RECIPIENT,
  SIGN,
  SUCCESS,
  ERROR,
  NEED_UPGRADE,

  // additional states from gateway
  RESOLVE_MERCHANT_BY_ID,
  MONITOR_AUTH,
  AUTH_USER_INTO,
  LIST_ACCOUNTS,
  SELECT_USER_ACCOUNT,
  SIGN_TX,
  MONITOR_SIGN_TX
}

type GatewayContextProps = {
  values: {
    stateRef: any;
    pid: string;
    authToken: string;
    token: string;
    state: State;
    authMode: string;
    operationId: string;
    bank: string;
    amount: number;
    account: any;
    accountList: any[];
    refId: string;
    errorString: string;
    recipientIban: string;
    recipientName: string;
    currency: string;
    redirectUrl: string;
    systemReady: boolean;
  };
  setters: {
    setPid: (value: string) => void;
    setAuthToken: (value: string) => void;
    setToken: (value: string) => void;
    setAuthMode: (value: string) => void;
    setOperationId: (value: string) => void;
    setBank: (value: string) => void;
    setAmount: (value: number) => void;
    setCurrency: (value: string) => void;
    setAccount: (value: any) => void;
    setAccountList: (value: any) => void;
    setRefId: (value: string) => void;
    setErrorString: (value: string) => void;
    setRecipientIban: (value: string) => void;
    setRecipientName: (value: string) => void;
    setRedirectUrl: (value: string) => void;
  };
};

const initialValues: GatewayContextProps = {
  values: {
    stateRef: undefined,
    pid: '',
    authToken: '',
    token: '',
    state: State.IDLE,
    authMode: '',
    operationId: '',
    bank: '',
    amount: 0,
    account: {},
    accountList: [],
    refId: '',
    errorString: '',
    recipientIban: '',
    recipientName: '',
    currency: 'SEK',
    redirectUrl: '',
    systemReady: false
  },
  setters: {
    setPid: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setToken: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setAuthToken: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setAuthMode: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setOperationId: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setBank: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setAmount: function (_value: number): void {
      throw new Error('Function not implemented.');
    },
    setCurrency: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setAccount: function (_value: any): void {
      throw new Error('Function not implemented.');
    },
    setAccountList: function (_value: any): void {
      throw new Error('Function not implemented.');
    },
    setRefId: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setErrorString: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setRecipientIban: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setRecipientName: function (_value: string): void {
      throw new Error('Function not implemented.');
    },
    setRedirectUrl: function (_value: string): void {
      throw new Error('Function not implemented.');
    }
  }
};

const GatewayContext = createContext(initialValues);

export const useGateway = () => {
  const context = useContext(GatewayContext);
  if (!context) {
    throw new Error('GatewayContext not available');
  }
  return context;
};

const GatewayProvider = ({ children }: BaseProps) => {
  const stateRef = StateMachineContext.useActorRef();
  const state = StateMachineContext.useSelector((state) => state.value);
  const systemReady = StateMachineContext.useSelector((state) => state.context.systemReady);
  const pid = StateMachineContext.useSelector((state) => state.context.pid);
  const connectToWebsocket = StateMachineContext.useSelector((state) => state.context.connectToWebsocket);
  const connectToWebsocketQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.CONNECT,
    returnObjectName: 'nonexistent',
    data: {},
    auto: connectToWebsocket
  });

  const updateData = useCallback(
    (data: any) => {
      stateRef.send({ type: SEvent.UPDATE_DATA, data });
    },
    [stateRef]
  );

  useSWComm({
    service: 'sw',
    method: 'status',
    onResponse: (data: any) => {
      if (systemReady === false) {
        updateData({ systemReady: true });
      }
      updateData({ wsConnected: data.graphqlConnected });
    }
  });

  useEffect(() => {
    if (connectToWebsocket) {
      App.sendMessage({ service: 'claim' });
      connectToWebsocketQuery.execute();
    }
  }, [connectToWebsocket, systemReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPid = useCallback(
    (pid: string) => {
      updateData({ pid: pid });
    },
    [updateData]
  );

  navigator.serviceWorker.addEventListener('controllerchange', function () {
    updateData({ systemReady: false, connectToWebsocket: false });
    App.sendMessage({ service: 'claim' });
  });

  const [token, setToken] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [authMode, setAuthMode] = useState('');
  const [operationId, setOperationId] = useState('');
  const [bank, setBank] = useState('');
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState('SEK');
  const [account, setAccount] = useState({});
  const [accountList, setAccountList] = useState<any>([]);
  const [refId, setRefId] = useState('');
  const [errorString, setErrorString] = useState('');
  const [recipientIban, setRecipientIban] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');

  const value: GatewayContextProps = {
    values: {
      stateRef,
      systemReady,
      pid,
      token,
      authToken,
      state,
      authMode,
      operationId,
      bank,
      amount,
      account,
      accountList,
      refId,
      errorString,
      recipientIban,
      recipientName,
      currency,
      redirectUrl
    },
    setters: {
      setPid,
      setToken,
      setAuthToken,
      setAuthMode,
      setOperationId,
      setBank,
      setAmount,
      setCurrency,
      setAccount,
      setAccountList,
      setRefId,
      setErrorString,
      setRecipientIban,
      setRecipientName,
      setRedirectUrl
    }
  };

  return <GatewayContext.Provider value={value}>{children}</GatewayContext.Provider>;
};

export default GatewayProvider;
