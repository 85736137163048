import React from 'react';
import { Button } from 'react-bootstrap';

import { faArrowUpRightFromSquare, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlueFrame from 'shared-components/components/BlueFrame';
import CopyButton from 'shared-components/components/CopyButton';

import style from './style.module.scss';

type NeedUpgradeProps = {
  link: string;
};

export const NeedUpgrade = ({ link }: NeedUpgradeProps) => {
  return (
    <>
      <BlueFrame style={{ padding: '20px', marginBottom: '32px' }}>
        <div style={{ marginBottom: '8px' }}>
          <a href={link}>{link}</a>
        </div>
        <CopyButton value={link} />
      </BlueFrame>
      <Button className="w-100" style={{ minHeight: '52px', fontWeight: '400' }} href={link} size="lg">
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        &nbsp; Upgrade account
      </Button>
      <div className={style.warning} style={{ marginTop: '16px' }}>
        <FontAwesomeIcon icon={faTriangleExclamation} /> Once this has been completed, please restart your payment
      </div>
    </>
  );
};
