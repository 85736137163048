import React from 'react';

import { createActorContext } from '@xstate/react';

import { machine } from 'utils/stateMachine';
import { BaseProps } from 'shared-components/utils/types';

export const StateMachineContext = createActorContext(machine);

export const StateMachineProvider = ({ children }: BaseProps) => {
  return <StateMachineContext.Provider>{children}</StateMachineContext.Provider>;
};

export default StateMachineProvider;
