import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useGateway } from 'providers/GatewayProvider';
import { BankID } from 'shared-components/components/BankID';
import BlueFrame from 'shared-components/components/BlueFrame';
import { usePrevious } from 'utils';

export const AddRecipient = () => {
  const {
    values: { recipientIban, recipientName, token, authMode }
  } = useGateway();
  const [progress, setProgress] = useState(0);
  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && prevToken.length > 0 && token.length === 0) {
      setProgress(1);
    } else {
      setProgress(0);
    }
  }, [prevToken, token]);

  return (
    <>
      <BlueFrame style={{ borderWidth: '2px' }}>
        <Row>
          <Col style={{ textAlign: 'left' }} md={7}>
            <center>{recipientName}</center>
          </Col>
        </Row>
        <Row className="d-md-none">
          <Col>&nbsp;</Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'left' }} md={7}>
            <center>{recipientIban}</center>
          </Col>
        </Row>
      </BlueFrame>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <BankID
          token={token}
          type={authMode}
          buttonDescription="Add Recipient"
          progress={progress}
          onSetProgress={(value) => setProgress(value)}
        />
      </div>
    </>
  );
};
